<!--
 * @Author: 张阳帅
 * @Date: 2024-09-23 17:52:37
 * @LastEditTime: 2024-11-14 16:17:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\signIn\signIn.vue
-->
<template>
  <div class="gcjsignin_page">
    <div class="page_top">
      <van-sticky offset-top="50px">
        <div class="one_item">
          <div class="item_left">
            就诊人
          </div>
          <div v-if="patientDict.patientCardList && patientDict.patientCardList.length>0" class="item_right">
            <div class="item_name">
              {{ patientDict.xm }} {{ patientDict.xbmc }} {{ patientDict.age }}岁
            </div>
            <div class="item_des">
              {{ patientDict.patientCardList[0].jzklxmc }} {{ patientDict.patientCardList[0].jzkh }}
            </div>
          </div>
        </div>
      </van-sticky>
      <div class="qdxz">
        <div v-html="hospInfo.content" />
      </div>
      <!-- <div  class="page_three">
      <div class="three_des">
        <van-icon v-if="signShow && !loading" class="des_icon" name="success" color="#1e80ff" size="12" />
        <van-button v-if="loading" class="load-btn" size="mini" loading type="info" plain block loading-text="定位更新中..." />
        <span v-else>{{ signShow ? signText : signNotTExt }}</span>
      </div>
      
      
      <div class="three_alert">
        提醒:您也可以通过身份证,医保卡在设备上签到
      </div>
    </div> -->
    </div>
    <template v-if="yyztList1.length >0 || yyztList2.length >0">
      <div v-for="(item,index) in yyztList1" :key="index">
        <div class="page_one">
          <div class="one_item">
            <div class="item_left">
              就诊日期:
            </div>
            <div class="item_right">
              {{ item.notSignInQueue.hyrq }}<span style="margin-left: 3px;">{{ item.notSignInQueue.fskssj }}-{{ item.notSignInQueue.fsjssj }}</span>
            </div>
          </div>
          <!-- <div class="one_item">
        <div class="item_left">
          预约时段:
        </div>
        <div class="item_right">
          
        </div>
      </div> -->
          <div class="one_item">
            <div class="item_left">
              就诊人:
            </div>
            <div class="item_right">
              {{ item.notSignInQueue.jzrxm }}
            </div>
          </div>
          <div class="one_item">
            <div class="item_left">
              科室名称:
            </div>
            <div class="item_right">
              {{ item.notSignInQueue.ksmc }}
            </div>
          </div>
          <div class="one_item">
            <div class="item_left">
              医生姓名:
            </div>
            <div class="item_right">
              {{ item.notSignInQueue.ysxm }}
            </div>
          </div>
          <div class="one_item">
            <div class="item_left">
              就诊位置:
            </div>
            <div class="item_right">
              {{ item.notSignInQueue.jzwz }}
            </div>
          </div>
          <div class="page_three">
            <!-- three_btn_disabled -->
            <div v-if="signShow" :class="loading ?'bottom_add_disabled ':'bottom_add'" @click="getLocationStatus ? debouncegetLocation(item) : signOther(item)">
              {{ getLocationStatus ? '签到' : '签到(不在医院范围)' }} 
            </div>
            <div v-else :class="loading ?'bottom_add_disabled ':'bottom_add'" @click="signOther(item)">
              签到
            </div>
          </div>
        </div>
      </div>
      <div class="signinlist_page">
        <div v-for="(item2, index) in yyztList2" :key="index" class="page_body">
          <van-divider dashed :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
            {{ item2.signInQueue.yyztmc }}
          </van-divider>
          <!-- <div class="body_title">
          {{ item2.signInQueue.yyztmc }}
        </div> -->
          <!-- <div class="body_type">{{ item.queueStatus=='1'?'候诊':'签到' }}</div> -->
          <div class="body_item">
            <div class="item_left">
              预约序号
            </div>
            <div class="item_right">
              <div class="item_name">
                {{ item2.signInQueue.serialNum }}
              </div>
            </div>
          </div>
          <div class="body_item">
            <div class="item_left">
              等待人数
            </div>
            <div class="item_right">
              <div class="item_num">
                {{ item2.signInQueue.frontNum }} 人
              </div>
            </div>
          </div>
          <div class="body_item">
            <div class="item_left">
              就诊人
            </div>
            <div class="item_right">
              <div class="item_name">
                {{ item2.signInQueue.patName }}
              </div>
            </div>
          </div>
          <div class="body_item">
            <div class="item_left">
              医生姓名
            </div>
            <div class="item_right">
              <div class="item_name">
                {{ item2.signInQueue.docName }}
              </div>
            </div>
          </div>
          <div class="body_item">
            <div class="item_left">
              科室名称
            </div>
            <div class="item_right">
              <div class="item_name">
                {{ item2.signInQueue.deptName }}
              </div>
            </div>
          </div>
          <div class="body_item">
            <div class="item_left">
              就诊位置
            </div>
            <div class="item_right">
              <div class="item_name">
                {{ item2.signInQueue.deptLocation }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <van-empty description="暂无签到数据" />
    </template>
    <!-- <van-dialog v-model="show" :title="hospInfo.descTitle" show-confirm-button theme="round-button" confirm-button-text="我知道了" confirm-button-color="#3A8AE5">
      <div class="body" v-html="hospInfo.content" />
    </van-dialog> -->
  </div>
</template>
<script>
import {gjcModel} from '../../../api/gjcApi'
import { WX_CONST } from "../../../utils/constant"
import { debounce } from '../../../utils/index'
import { Toast } from "vant"
import {getItem} from "@/utils/cache"
export default {
    data(){
      return{
        patientDict:{}, //首页就诊人列表
        latitude: '',
        longitude: '',
        detail:{},
        signText:' 已进入医院签到区域,点击签到排队',
        signNotTExt:' 未进入医院签到区域,暂不能签到',
        signShow:true,
        getLocationStatus:true,
        timer:null,
        loading:false,
        yyztList1:[],
        yyztList2:[],
        show:false,
        hospInfo:{}

      }
    },
    created(){
    //解决ios定位失败的问题
      this.patientDict = JSON.parse(this.$store.getters.patient)
    },
    mounted() {
      this.getSignInQueueDetailData()
      this.locationCheckSwitchMth()
      this.gjcDepartDesc()
    },
    beforeDestroy() {
      clearInterval(this.timer)
    },
    methods:{
      gjcDepartDesc(){
          gjcModel.gjcDepartDesc('QDXZ').then((result) => {
                console.log('医院介绍', result)
                this.hospInfo = result.data
                  this.show = true
            })
        },
      //获取签到详情
      getSignInQueueDetailData(){
        let params={
          yydm:'',//医院代码
          patId:this.patientDict.patientId
        }
        // gjcModel.getSignInQueueDetailData(params).then(res=>{
        //   console.log('获取签到详情', res.data)
        //   this.detail = res.data
        // })

        gjcModel.signInList(params).then(res=>{
          console.log('获取签到List', res.data)
         this.yyztList1 = res.data.filter(item=>item.yyzt == '1')
         this.yyztList2 = res.data.filter(item=>item.yyzt == '2')
        })
      },
      signOther(item){
        if (this.loading) {
          return
        }
        this.detail = item
        this.showConfirmSignOther()
      },

      debouncegetLocation:debounce(function(item){
        this.getSignInQueueSignInData(item)
     },500,true),

    locationCheckSwitchMth(){
      gjcModel.locationCheckSwitch().then(res=>{
        if(res.data){
          this.signShow = true
          this.getLocation()
          this.timer = setInterval(()=>{
            this.getLocation()
          },30000)
        }else{
          this.signShow = false
          this.getLocationStatus = true
        }
      })
    },
      //获取经纬度
      getLocation(){
        this.loading = true
        const params = {
          appid: WX_CONST.appid,
          url: window.location.href
        }
        let that = this
        console.log('url: window.location.href',window.location.href)
        gjcModel.getCreateJsapiSignatureData(params).then((wxRes)=>{
          // eslint-disable-next-line no-undef
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: WX_CONST.appid, // 必填，公众号的唯一标识
            timestamp: (wxRes.data.timestamp).toString(), // 必填，生成签名的时间戳
            nonceStr: wxRes.data.nonceStr, // 必填，生成签名的随机串
            signature: wxRes.data.signature,// 必填，签名
            jsApiList: ['getLocation'] // 必填，需要使用的JS接口列表
          })
          setTimeout(() => {
            // eslint-disable-next-line no-undef
            wx.getLocation({ 
            type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02' -->
            success: function (res) { 
              that.latitude = String(res.latitude) // 纬度，浮点数，范围为90 ~ -90 -->
              that.longitude = String(res.longitude) // 经度，浮点数，范围为180 ~ -180。 -->
              // var speed = res.speed; // 速度，以米/每秒计 -->
              // var accuracy = res.accuracy; // 位置精度 -->
              console.log('获取经纬度', res) 
              that.getLocationResult()
            }, 
            fail: function (err) { 
              that.loading = false
              that.getLocationStatus = false
              console.log('获取经纬度失败', err) 
              clearInterval(that.timer)
              if (err.errMsg !=='getLocation:invalid signature') {
                that.showConfirm()
              }else{
                //第一次获取地址报getLocation:invalid signature错误，刷新页面
                if (getItem("updatePage") == 1) {
                  window.location.reload()
                  localStorage.removeItem('updatePage')
                }
              }
            }
          }) 
          },1000)
        })
      },
      showConfirm(){
        this.$dialog.confirm({
          title: '定位服务异常',
          message: '无法根据定位来进行签到。为了避免您能按时就诊,请您确认已经到院后再进行签到。',
          confirmButtonText: '我知道了',
          showCancelButton: false
        }).then(() => {
        }).catch(() => {
        })
      },
      showConfirmSignOther(){
        this.$dialog.confirm({
          title: '不在签到范围',
          message: '您未到院,此时签到有可能过号,是否签到？',
          confirmButtonText: '签到',
          cancelButtonText: '暂不签到'
        }).then(() => {
          this.debouncegetLocation(this.detail)
        }).catch(() => {
          
        })
      },
      //查询定位结果
      getLocationResult(){
        const params = {
          latitude:this.latitude,
          longitude:this.longitude
        }
        gjcModel.getSignInLocationData(params).then(res=>{
          this.loading = false
          if (res.data) {
            this.getLocationStatus = true
          }else{
            this.getLocationStatus = false
          }
          console.log('查询定位结果', res.data)
          // this.getSignInQueueSignInData()
        }).catch(()=>{
          this.loading = false
        })
      },
      //提交签到
      getSignInQueueSignInData(item){
        if (this.loading) {
          return
        }
        Toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      })
      console.log(item)
        const params = {
          id:item.appointId 
        }
        gjcModel.getSignInQueueSignInData(params).then(res=>{
          Toast.clear()
          console.log('签到响应', res.data)
          this.$toast('签到成功')
          this.getSignInQueueDetailData()
        }).catch((err)=>{
          Toast.clear()
          this.$toast(err.msg)
        })
      }
    }
}
</script>
<style scoped lang='less'>
.gcjsignin_page{
    background-color: #f5f5f5;
    min-height: 100vh;
    .page_top {
  border-bottom: 1px solid #f5f5f5;

  .one_item {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    padding: 20px 24px;
    border-bottom: 1px solid #f5f5f5;
    background-color: #1e80ff;

    .item_left {
      font-size: 32px;
      color: white;
      ;
      line-height: 70px;
    }

    .item_right {
      text-align: right;
      font-size: 30px;
      color: white;

      .item_des {
        font-size: 24px;
      }
    }
  }
}
}
.page_one{
    margin: 30px 24px;
    background-color: white;
    border-radius: 10px;
    // border-bottom: 20px solid #f5f5f5;
}
.one_item{
    display: flex;
    justify-content: space-between;
    justify-items: center;
    padding: 10px 24px;
    border-bottom: 1px solid #f5f5f5;
    .item_left{
        font-size: 32px;
        color: #333333;

        .item_docName{
            font-weight: bold;

            .item_docTitle{
                margin-left: 24px;
                font-size: 24px;
                color: #666;
                font-weight: normal;
            }
        }
        .item_deptName{
            font-size: 24px;
            color: #999999;
        }
    }
    .item_fee{
        font-size: 32px;
        color: #f80404;
    }
    .item_right{
        text-align: right;
        font-size: 32px;
        color: #999999;
        .item_des{
            font-size: 26px;
        }
    }
}
.page_three{
  display: flex;
  justify-content: center;
  align-items: center;
  .three_btn{
        margin: 40px 40px 20px 40px;
        padding: 20px;
        background-color: #1e80ff;
        border-radius: 10px;
        color: white;
        font-size: 32px;
        line-height: 100px;
        text-align: center;
        width: 100px;
        height: 100px;
        
    }
    .three_btn_disabled{
        margin: 40px 40px 20px 210px;
        padding: 20px;
        background-color: #9A9A9A;
        border-radius: 10px;
        color: white;
        font-size: 32px;
        line-height: 100px;
        text-align: center;
        width: 100px;
        height: 100px;
        
    }
    .three_des{
        display: flex;
        justify-content: center;
        margin: 0 24px;
        text-align: center;
        font-size: 22px;
        line-height: 22px;
        color: #999;
        width: 100%;

        .des_icon{
            width: 20px;
            height: 20px;
            border: #1e80ff solid 2px;;
            border-radius: 50%;
        }
    }
    .three_alert{
        display: flex;
        justify-content: center;
        margin: 10px 24px 0 24px;
        font-size: 22px;
        color: #999;
    }
    .load-btn{
      border: 0;
      background: #f5f5f5;
      color: #9A9A9A;
      font-size: 24px;
    }
}

.signinlist_page{
      width: 100%;
      padding-bottom: 100px;
      margin-top: 20px;
    }
    .page_body{
        margin: 20px 24px;
        background-color: #fff;
        border-radius: 10px;
        .body_title{
            padding: 10px 24px;
            font-size: 32px;
            color: #1e80ff;
            font-weight: bold;
        }
        .body_item{
            display: flex;
            justify-content: space-between;
            justify-items: center;
            padding: 15px 24px;
            .item_left{
                font-size: 30px;
                color: #666;;
                line-height: 40px;
            }
            .item_right{
                margin-left: 30px;
                text-align: right;
                font-size: 32px;
                color: #333;
                line-height: 40px;
                .item_des{
                    font-size: 32px;
                }
                .item_num{
                    font-size: 40px;
                    color: #f80404;
                }
            }
        }
    }
    .page-box{
        padding: 0 30px;
    }
    .van-dialog{
  top: 50% !important;
}
.hosInfo{
  font-size: 28px;
  .adress{
    padding: 60px 10px 10px 0px;
    font-size: 24px;
    color: #555b5e;
  }
  .name{
    padding: 10px 0;
    font-weight: bolder;
  }
}
.body{
  padding: 10px 20px;
  font-size: 26px;
  color: #222b31;
  line-height: 1.5;
}
.qdxz{
  padding: 10px 20px;
}
.bottom_add ,.bottom_add_disabled{
        // display: flex;
        // justify-content: center;
        // justify-items: center;
        margin: 24px;
        width: 100%;
        padding: 5px 50px 6px;
        background-color: #1e8dff;
        border-radius: 50px;
        font-size: 32px;
        color: #fff;
        text-align: center;
        // border:1px solid #1e8dff;
    }
    .bottom_add_disabled{
      background-color: #9A9A9A;
        border-radius: 50px;
        color: white;
        border:1px solid #9a9a9a;
    }
</style>
